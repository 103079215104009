import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable } from 'rxjs';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard 
{

  constructor(private router: Router, private jwt: JwtHelperService, private userService: UserService) { }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree
  {
    if (this.jwt.isTokenExpired())
    {
      return this.router.createUrlTree([""]);
    } else
    {
      return !childRoute.data.denyTo?.includes(this.userService.user.role) ? true : this.router.createUrlTree(["/inside"]);
    }
  }
  canLoad(
    route: Route,
    segments: UrlSegment[]): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree
  {
    if (this.jwt.isTokenExpired())
    {
      return this.router.createUrlTree([""]);
    } else
    {
      return true;
    }
  }
}
