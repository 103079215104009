import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { GetAppComponent } from './public/get-app/get-app.component';
import { ImprintComponent } from './public/imprint/imprint.component';
import { LoginComponent } from './public/login/login.component';
import { AuthGuard } from './services/auth.guard';
import { LoggedInGuard } from './services/logged-in.guard';

const routes: Routes = [
  {
    path: '',
    canActivate: [LoggedInGuard],
    component: LoginComponent
  },
  {
    path: 'inside',
    canLoad: [AuthGuard],
    loadChildren: () => import('./inside/inside.module').then(m => m.InsideModule),
  },
  {
    path: 'getApp',
    component: GetAppComponent
  },
  {
    path: 'imprint',
    component: ImprintComponent
  },
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: ''
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
