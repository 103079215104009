<div class="login-wrapper">
    <form class="login" [formGroup]="loginForm" (ngSubmit)="login()">
        <a href="#" class="logo"></a>
        <section class="title">
            <h3 class="welcome">Willkommen im</h3>
            TIPP Diary Manager
            <h5 class="hint">Loggen Sie sich bitte mit den erhaltenen Daten ein.</h5>
        </section>
        <div class="login-group">
            <clr-input-container>
                <label class="clr-sr-only">E-Mail</label>
                <input type="email" name="email" clrInput placeholder="E-Mail" autocomplete="off" formControlName="email" />
            </clr-input-container>
            <clr-password-container>
                <label class="clr-sr-only">Password</label>
                <input type="password" name="password" clrPassword placeholder="Password" formControlName="password" />
            </clr-password-container>
            <div class="error active" *ngIf="loginfailed">
                Invalid user name or password
            </div>
            <button type="submit" class="btn btn-primary">LOGIN</button>
        </div>
    </form>

    <a class="footer" href="#" routerLink="/imprint">Impressum</a>
</div>
