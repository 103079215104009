import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ClarityModule } from '@clr/angular';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoginComponent } from './public/login/login.component';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import localeDe from '@angular/common/locales/de';

import '@cds/core/icon/register.js';
import { angleIcon, ClarityIcons, cogIcon, downloadIcon, formIcon, homeIcon, infoStandardIcon, noAccessIcon, pencilIcon, plusIcon, userIcon, usersIcon } from '@cds/core/icon';
import { registerLocaleData } from '@angular/common';
import { JwtModule } from '@auth0/angular-jwt';
import { ToastrModule } from 'ngx-toastr';
import { ImprintComponent } from './public/imprint/imprint.component';
import { GetAppComponent } from './public/get-app/get-app.component';
import { environment } from 'src/environments/environment';

ClarityIcons.addIcons(homeIcon, cogIcon, userIcon, usersIcon, downloadIcon, formIcon, angleIcon, noAccessIcon, plusIcon, infoStandardIcon, pencilIcon);

registerLocaleData(localeDe);

export function tokenGetter()
{
  return localStorage.getItem("access_token");
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ImprintComponent,
    GetAppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ClarityModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ToastrModule.forRoot({
      positionClass: "toast-bottom-right"
    }),
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        allowedDomains: ["api.tippdiary.apps.hosting"],
        disallowedRoutes: [`${environment.apiUrl}/login`],
      },
    })
  ],
  providers: [{ provide: LOCALE_ID, useValue: "de-DE" }],
  bootstrap: [AppComponent]
})
export class AppModule { }
