<header class="header-4" style="background: linear-gradient(315deg, var(--primary-background), var(--secondary-background))">
    <div class="branding">
        <a href="#" class="nav-link">
            <div class="logo"></div>
            <span class="title">Manager</span>
        </a>
    </div>
</header>

<div class="card" style="width: 530px; margin: 150px auto;">
    <div class="card-block">
        <a href="#" routerLink="/" class="logo big"></a>

        <div style="display:flex; justify-content: center; padding-bottom: 20px">
            <h4 style="font-weight: bold">Impressum</h4>
        </div>

        <div style="white-space: pre-line">

            <b>Angaben gemäß § 5 TMG:</b>
            <p>Klinik für Kinder- und Jugendmedizin, Allergologie, Pneumologie und Mukoviszidose.<br /><br />
                Prof . Dr. med. Stefan Zielen<br />
                Universitätsklinikum Frankfurt<br />
                Theodor-Stern-Kai 7<br />
                60590 Frankfurt am Main</p>
            <br />
            <b>Kontakdaten:</b>
            <p>Telefon: +49 69 63 01-0<br />
                Fax: +49 69 6301-63 01<br />
                E-Mail: info@kgu.de<br />
                Internet: www.kgu.de</p>
        </div>
    </div>
</div>
