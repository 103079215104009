<div class="content-container">

    <div class="logo big"></div>

    <div class="badges">
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 564 605">
            <image width="564" height="605" href="/assets/badges_map.png"></image>
            <a
                xlink:href="https://play.google.com/store/apps/details?id=com.kgu.tippdiary&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
                <rect x="0" y="0" fill="#fff" opacity="0" width="564" height="169"></rect>
            </a>
            <a xlink:href="https://apps.apple.com/de/app/tipp-diary/id1598597989#?platform=iphone">
                <rect x="0" y="208" fill="#fff" opacity="0" width="564" height="188"></rect>
            </a>
            <a xlink:href="https://tippdiary.apps.hosting/apk/TIPPDiary.apk">
                <rect x="0" y="435" fill="#fff" opacity="0" width="564" height="170"></rect>
            </a>
        </svg>
    </div>
</div>
