import { Injectable } from '@angular/core';
import { User } from '../models/user';

@Injectable({
  providedIn: 'root'
})
export class UserService
{

  constructor() { }

  get user(): User
  {
    return JSON.parse(localStorage.getItem("userData") ?? "");
  }
}
