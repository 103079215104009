import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/services/api.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit
{
  loginForm: UntypedFormGroup;
  loginfailed = false;

  constructor(private router: Router, private fb: UntypedFormBuilder, private api: ApiService, private toast: ToastrService, private userService: UserService)
  {
    this.loginForm = fb.group({
      email: ["", [Validators.required, Validators.email]],
      password: ["", Validators.required],
      rememberMe: false,
    })
  }

  ngOnInit(): void
  {
  }

  login()
  {
    const _form = this.loginForm;
    this.api.login(_form.get("email")?.value, _form.get("password")?.value).subscribe(res =>
    {
      if (res.success)
      {
        //TODO set jwt
        localStorage.setItem("access_token", res.access_token);

        //initiate user
        this.api.getUser(res.uid).subscribe(data =>
        {
          localStorage.setItem("userData", JSON.stringify(data)); //cache
          this.router.navigate(["/inside"]);
          this.toast.success("Willkommen zurück!");
        });
      } else
      {
        this.toast.error("Fehlerhafte E-Mail oder Passwort.");
      }
    }, err => this.toast.error("Während des Logins ist ein Fehler aufgetreten."))
  }

}
