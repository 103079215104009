import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ApiResponse, Token } from '../models/api';
import { Evaluation } from '../models/evaluation';
import { Patient } from '../models/patient';
import { Studycenter } from '../models/studycenter';
import { User } from '../models/user';

@Injectable({
  providedIn: 'root'
})
export class ApiService
{

  constructor(private http: HttpClient) { }

  //getter
  getExport(from: string, to: string)
  {
    return this.http.post<Blob>(`${environment.apiUrl}/export?from=${from}&to=${to}`, undefined, { responseType: 'blob' as 'json' });
  }

  getEvaluation(id: number, visitFrom: string, visitTo: string)
  {
    return this.http.get<Evaluation>(`${environment.apiUrl}/evaluation/${id}?visitFrom=${visitFrom}&visitTo=${visitTo}`);
  }

  getStudycenters()
  {
    return this.http.get<Studycenter[]>(`${environment.apiUrl}/studycenters`);
  }

  getVisits(id: number)
  {
    return this.http.get<string[]>(`${environment.apiUrl}/evaluation/${id}/visits`);
  }

  getPatients()
  {
    return this.http.get<Patient[]>(`${environment.apiUrl}/patients`);
  }

  getPatient(id: number)
  {
    return this.http.get<Patient>(`${environment.apiUrl}/patient/${id}`);
  }

  getUsers()
  {
    return this.http.get<User[]>(`${environment.apiUrl}/users`);
  }

  getUser(id: number)
  {
    return this.http.get<User>(`${environment.apiUrl}/users/${id}`);
  }

  //setter
  login(email: string, password: string)
  {
    return this.http.post<Token>(`${environment.apiUrl}/login`, { email, password });
  }

  editUser(data: User, id: number)
  {
    return this.http.patch<ApiResponse>(`${environment.apiUrl}/users/${id}`, data);
  }

  addUser(data: User)
  {
    return this.http.post<ApiResponse>(`${environment.apiUrl}/users/add`, data);
  }

  saveVisit(id: number, date: string)
  {
    return this.http.post<ApiResponse>(`${environment.apiUrl}/evaluation/${id}/savevisit`, { date });
  }
}
